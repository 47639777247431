import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/packages/mdx/src/components/Layout/Default.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ButtonExternal = makeShortcode("ButtonExternal");
const ButtonInternal = makeShortcode("ButtonInternal");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "the-bed-and-breakfast-to-visit-nancy-and-metz"
    }}>{`The `}<strong parentName="h1">{`bed and breakfast`}</strong>{` to visit `}<strong parentName="h1">{`Nancy`}</strong>{` and `}<strong parentName="h1">{`Metz`}</strong></h1>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/28e4fc40eb91a87992ead592da6b2869/1fe05/image.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.25766871165644%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAABAAD/8QAFQEBAQAAAAAAAAAAAAAAAAAAAAL/2gAMAwEAAhADEAAAAVCzok8mP//EABkQAAIDAQAAAAAAAAAAAAAAAAABAgMSEf/aAAgBAQABBQJ2T1bLQojno0ucrP/EABYRAAMAAAAAAAAAAAAAAAAAAAEQEf/aAAgBAwEBPwGhf//EABYRAQEBAAAAAAAAAAAAAAAAAAABEf/aAAgBAgEBPwGtf//EABwQAAIBBQEAAAAAAAAAAAAAAAABIQIRMTNBof/aAAgBAQAGPwLKLs4SkTSjX6f/xAAbEAEAAwEAAwAAAAAAAAAAAAABABFRMSFBkf/aAAgBAQABPyG+Goclx9nSIl+GWxR0bLKLPlwfE//aAAwDAQACAAMAAAAQy+//xAAWEQEBAQAAAAAAAAAAAAAAAAAAARH/2gAIAQMBAT8Qo2P/xAAXEQADAQAAAAAAAAAAAAAAAAAAASER/9oACAECAQE/EKVNH//EABwQAAICAgMAAAAAAAAAAAAAAAERACExQXGBof/aAAgBAQABPxBk7FCmri14U7qA5AJsElS78LCFj2JN9APsRgdtwKf/2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/28e4fc40eb91a87992ead592da6b2869/f994e/image.webp 163w", "/static/28e4fc40eb91a87992ead592da6b2869/bcd70/image.webp 325w", "/static/28e4fc40eb91a87992ead592da6b2869/5d8d7/image.webp 650w", "/static/28e4fc40eb91a87992ead592da6b2869/6c7d1/image.webp 960w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/28e4fc40eb91a87992ead592da6b2869/367e5/image.jpg 163w", "/static/28e4fc40eb91a87992ead592da6b2869/ab07c/image.jpg 325w", "/static/28e4fc40eb91a87992ead592da6b2869/ebabe/image.jpg 650w", "/static/28e4fc40eb91a87992ead592da6b2869/1fe05/image.jpg 960w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/28e4fc40eb91a87992ead592da6b2869/ebabe/image.jpg",
              "alt": "authentic french bed and breakfast to visit france",
              "title": "authentic french bed and breakfast to visit france",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span><strong parentName="p">{`Bed and breakfast`}</strong></p>
    <h2 {...{
      "id": "a-romantic-night-in-a-castle-in-lorraine-"
    }}>{`A `}<strong parentName="h2">{`romantic night`}</strong>{` in a `}<strong parentName="h2">{`castle`}</strong>{` in `}<strong parentName="h2">{`Lorraine`}</strong>{` ?`}</h2>
    <h3 {...{
      "id": "rest-and-calm-assured--a-few-minutes-from-nancy"
    }}>{`Rest and calm assured … A few minutes from `}<strong parentName="h3">{`Nancy`}</strong></h3>
    <p>{`We have been operating as a `}<strong parentName="p">{`bed and breakfast`}</strong>{` for 25 years. It is a great pleasure to welcome more than 5,000 travelers to our premises every year.`}</p>
    <p>{`We welcome you so that you can `}<strong parentName="p">{`discover Lorraine`}</strong>{`, ensure a moment of `}<strong parentName="p">{`romance`}</strong>{`, you will escape from your daily life, or other, it is with enthusiasm that we will allow you to realize your pleasures.`}</p>
    <p>{`It is important for the Château de Morey to promise you comfort and calm. Our rooms are all perfectly insulated with exposed stone walls, they all ensure exceptional comfort with quality bedding. All our rooms also have the particularity of being very spacious.`}</p>
    <ButtonExternal type="booking" mdxType="ButtonExternal">Book</ButtonExternal>
    <h2 {...{
      "id": "for-your-intimate-nights-in-lorraine"
    }}>{`For your intimate nights in `}<strong parentName="h2">{`Lorraine`}</strong></h2>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/e5f290bd73f3916fcf58131988aa1728/a4af9/tourelle2.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.87116564417178%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAQFAf/EABYBAQEBAAAAAAAAAAAAAAAAAAECA//aAAwDAQACEAMQAAABd2IxndIjg//EABkQAAMBAQEAAAAAAAAAAAAAAAABAhESIf/aAAgBAQABBQLqGZ5pD0bo2j//xAAVEQEBAAAAAAAAAAAAAAAAAAAQEf/aAAgBAwEBPwGH/8QAFxEBAAMAAAAAAAAAAAAAAAAAAAERIf/aAAgBAgEBPwG4Y//EABsQAAEEAwAAAAAAAAAAAAAAAAABEBEhEjFh/9oACAEBAAY/AppekvOS2bP/xAAbEAADAQADAQAAAAAAAAAAAAAAAREhMUFh4f/aAAgBAQABPyHCr6vBrb6OGWLJHBFzZhqfM//aAAwDAQACAAMAAAAQZw//xAAWEQEBAQAAAAAAAAAAAAAAAAABACH/2gAIAQMBAT8QEmW3/8QAFhEBAQEAAAAAAAAAAAAAAAAAARAh/9oACAECAQE/EEHY/8QAHhABAAICAgMBAAAAAAAAAAAAAQARIUExUWFxobH/2gAIAQEAAT8QSiAtifqNRCjPPbfcuC78m4SwBgOT3mMgMg0AcfYwDun/2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/e5f290bd73f3916fcf58131988aa1728/f994e/tourelle2.webp 163w", "/static/e5f290bd73f3916fcf58131988aa1728/bcd70/tourelle2.webp 325w", "/static/e5f290bd73f3916fcf58131988aa1728/5d8d7/tourelle2.webp 650w", "/static/e5f290bd73f3916fcf58131988aa1728/c4443/tourelle2.webp 975w", "/static/e5f290bd73f3916fcf58131988aa1728/5cc32/tourelle2.webp 1300w", "/static/e5f290bd73f3916fcf58131988aa1728/1dd8b/tourelle2.webp 2302w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/e5f290bd73f3916fcf58131988aa1728/367e5/tourelle2.jpg 163w", "/static/e5f290bd73f3916fcf58131988aa1728/ab07c/tourelle2.jpg 325w", "/static/e5f290bd73f3916fcf58131988aa1728/ebabe/tourelle2.jpg 650w", "/static/e5f290bd73f3916fcf58131988aa1728/cdb69/tourelle2.jpg 975w", "/static/e5f290bd73f3916fcf58131988aa1728/2616f/tourelle2.jpg 1300w", "/static/e5f290bd73f3916fcf58131988aa1728/a4af9/tourelle2.jpg 2302w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/e5f290bd73f3916fcf58131988aa1728/ebabe/tourelle2.jpg",
              "alt": "bed and breakfast nancy",
              "title": "bed and breakfast nancy",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span><strong parentName="p">{`bed and breakfast close to Nancy`}</strong></p>
    <h3 {...{
      "id": "this-bridal-room-in-the-heart-of-the-meurthe-et-moselle-region-promises-you-a-romantic-stay"
    }}>{`This bridal room in the heart of the `}<strong parentName="h3">{`Meurthe et Moselle`}</strong>{` region promises you a romantic stay.`}</h3>
    <h4 {...{
      "id": "the-tourelle-for-the-lovebirds-in-bed-and-breakfast"
    }}>{`The Tourelle for the lovebirds in bed and breakfast`}</h4>
    <p>{`This room is located in one of the towers of the `}<strong parentName="p">{`Castle`}</strong>{`. The charm of its exposed stones transports you in a medieval atmosphere. Spend your night in a canopy bed of 180m, comfort and calm are assured.`}</p>
    <p>{`Enjoy a view on our large forest and on the bridle path of the Castle.`}</p>
    <ButtonExternal type="booking" mdxType="ButtonExternal">Book this room</ButtonExternal>
    <h2 {...{
      "id": "the-favorite-of-the-chatelaine-lorraine"
    }}>{`The favorite of the Chatelaine `}<strong parentName="h2">{`Lorraine`}</strong></h2>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/5642ba84ac9673b8d7e3612c6b9d07da/a4af9/chambres-dhotes-airbnb-nancy.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.87116564417178%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAMEAv/EABYBAQEBAAAAAAAAAAAAAAAAAAMAAf/aAAwDAQACEAMQAAABn0teJWSB3//EABgQAAMBAQAAAAAAAAAAAAAAAAECEgAR/9oACAEBAAEFAgtJC8POkTix07//xAAVEQEBAAAAAAAAAAAAAAAAAAABEP/aAAgBAwEBPwEZ/8QAFhEBAQEAAAAAAAAAAAAAAAAAAAEh/9oACAECAQE/AbGP/8QAGhAAAgIDAAAAAAAAAAAAAAAAAAEQMREhUf/aAAgBAQAGPwLPBwtlss//xAAbEAEAAwADAQAAAAAAAAAAAAABABEhMUFRYf/aAAgBAQABPyHaBY4ljDdF+TZhGFHosfVt1H7T/9oADAMBAAIAAwAAABDD/wD/xAAXEQADAQAAAAAAAAAAAAAAAAAAAREx/9oACAEDAQE/EEPCH//EABYRAQEBAAAAAAAAAAAAAAAAAAARMf/aAAgBAgEBPxCGqf/EAB0QAQEAAgEFAAAAAAAAAAAAAAERACFRMUGBsdH/2gAIAQEAAT8QODNdIUQ2YBFUiMJ7w8b1bLnU+h5T7kYBpV7WTN2jOM//2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/5642ba84ac9673b8d7e3612c6b9d07da/f994e/chambres-dhotes-airbnb-nancy.webp 163w", "/static/5642ba84ac9673b8d7e3612c6b9d07da/bcd70/chambres-dhotes-airbnb-nancy.webp 325w", "/static/5642ba84ac9673b8d7e3612c6b9d07da/5d8d7/chambres-dhotes-airbnb-nancy.webp 650w", "/static/5642ba84ac9673b8d7e3612c6b9d07da/c4443/chambres-dhotes-airbnb-nancy.webp 975w", "/static/5642ba84ac9673b8d7e3612c6b9d07da/5cc32/chambres-dhotes-airbnb-nancy.webp 1300w", "/static/5642ba84ac9673b8d7e3612c6b9d07da/1dd8b/chambres-dhotes-airbnb-nancy.webp 2302w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/5642ba84ac9673b8d7e3612c6b9d07da/367e5/chambres-dhotes-airbnb-nancy.jpg 163w", "/static/5642ba84ac9673b8d7e3612c6b9d07da/ab07c/chambres-dhotes-airbnb-nancy.jpg 325w", "/static/5642ba84ac9673b8d7e3612c6b9d07da/ebabe/chambres-dhotes-airbnb-nancy.jpg 650w", "/static/5642ba84ac9673b8d7e3612c6b9d07da/cdb69/chambres-dhotes-airbnb-nancy.jpg 975w", "/static/5642ba84ac9673b8d7e3612c6b9d07da/2616f/chambres-dhotes-airbnb-nancy.jpg 1300w", "/static/5642ba84ac9673b8d7e3612c6b9d07da/a4af9/chambres-dhotes-airbnb-nancy.jpg 2302w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/5642ba84ac9673b8d7e3612c6b9d07da/ebabe/chambres-dhotes-airbnb-nancy.jpg",
              "alt": "bed and breakfast near metz",
              "title": "bed and breakfast near metz",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span><strong parentName="p">{`bed and breakfast near metz`}</strong></p>
    <h3 {...{
      "id": "a-night-near-nancy-in-a-princess-bedroom"
    }}>{`A night near Nancy in a princess bedroom`}</h3>
    <p>{`A spacious room of 64m2 and its four-poster bed will make you live a night of king and queen. Enjoy a large Italian shower and a lounge area to relax. A supreme view on the Natagne valley with its Lorraine colors sketches.`}</p>
    <ButtonExternal type="booking" mdxType="ButtonExternal">Book</ButtonExternal>
    <h2 {...{
      "id": "a-family-moment-in-the-family-suite-for-a-stay-in-nancy-"
    }}>{`A family moment in the Family Suite for a stay in `}<strong parentName="h2">{`Nancy`}</strong>{` ?`}</h2>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/c3536ba51be167d9b8df18ef93761b14/a4af9/suite.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.87116564417178%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAQFA//EABQBAQAAAAAAAAAAAAAAAAAAAAL/2gAMAwEAAhADEAAAAV3JFEvcREP/xAAZEAADAQEBAAAAAAAAAAAAAAAAARECAxL/2gAIAQEAAQUCsOe7qpiRxfke4//EABcRAQADAAAAAAAAAAAAAAAAAAABETH/2gAIAQMBAT8BnFP/xAAVEQEBAAAAAAAAAAAAAAAAAAACEP/aAAgBAgEBPwEz/8QAGRAAAgMBAAAAAAAAAAAAAAAAACEBAhAR/9oACAEBAAY/Ajg7bKP/xAAbEAEBAQACAwAAAAAAAAAAAAABEQAhMUFRcf/aAAgBAQABPyF4Hu6Epwd6liIzvXTlH3vBL9ymBLv/2gAMAwEAAgADAAAAEEM//8QAFhEBAQEAAAAAAAAAAAAAAAAAAAER/9oACAEDAQE/EJNP/8QAFhEBAQEAAAAAAAAAAAAAAAAAEQEA/9oACAECAQE/EBaajv/EABoQAQEBAAMBAAAAAAAAAAAAAAERACExQWH/2gAIAQEAAT8QQ2leJkNH1IAajpAHA1N8g84ihhG9rxqYqgTrf//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/c3536ba51be167d9b8df18ef93761b14/f994e/suite.webp 163w", "/static/c3536ba51be167d9b8df18ef93761b14/bcd70/suite.webp 325w", "/static/c3536ba51be167d9b8df18ef93761b14/5d8d7/suite.webp 650w", "/static/c3536ba51be167d9b8df18ef93761b14/c4443/suite.webp 975w", "/static/c3536ba51be167d9b8df18ef93761b14/5cc32/suite.webp 1300w", "/static/c3536ba51be167d9b8df18ef93761b14/1dd8b/suite.webp 2302w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/c3536ba51be167d9b8df18ef93761b14/367e5/suite.jpg 163w", "/static/c3536ba51be167d9b8df18ef93761b14/ab07c/suite.jpg 325w", "/static/c3536ba51be167d9b8df18ef93761b14/ebabe/suite.jpg 650w", "/static/c3536ba51be167d9b8df18ef93761b14/cdb69/suite.jpg 975w", "/static/c3536ba51be167d9b8df18ef93761b14/2616f/suite.jpg 1300w", "/static/c3536ba51be167d9b8df18ef93761b14/a4af9/suite.jpg 2302w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/c3536ba51be167d9b8df18ef93761b14/ebabe/suite.jpg",
              "alt": "bed and breakfast near nancy",
              "title": "bed and breakfast near nancy",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span><strong parentName="p">{`bnb near nancy`}</strong></p>
    <h3 {...{
      "id": "enjoy-your-time-with-your-family-in-lorraine-near-nancy-and-metz"
    }}>{`Enjoy your time with your family in `}<strong parentName="h3">{`Lorraine`}</strong>{` near `}<strong parentName="h3">{`Nancy and Metz`}</strong>{`.`}</h3>
    <p>{`The Chateau welcomes you as a couple as well as a family, by providing a majestic `}<strong parentName="p">{`room`}</strong>{` for your couple accompanied by an annex room for your children.`}</p>
    <p>{`You will enjoy a spacious bathroom and a breathtaking view of the XIIth century Romanesque church and the great `}<strong parentName="p">{`Lorraine valley`}</strong>{`.`}</p>
    <ButtonExternal type="booking" mdxType="ButtonExternal">Book</ButtonExternal>
    <h2 {...{
      "id": "sweet-printanière-sweet-lorraine"
    }}>{`Sweet Printanière, Sweet `}<strong parentName="h2">{`Lorraine`}</strong></h2>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/1cf0c9321a39b0681be39d8ea400489d/a4af9/printaniere.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.87116564417178%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAQFAgP/xAAWAQEBAQAAAAAAAAAAAAAAAAABAgP/2gAMAwEAAhADEAAAAdMTWs75kcT/xAAcEAABAwUAAAAAAAAAAAAAAAABAAISAwQRISP/2gAIAQEAAQUCbpSMcxVN5BuBxX//xAAWEQEBAQAAAAAAAAAAAAAAAAAAARH/2gAIAQMBAT8BrX//xAAXEQEAAwAAAAAAAAAAAAAAAAAAAQIR/9oACAECAQE/Aawx/8QAGhAAAQUBAAAAAAAAAAAAAAAAAAEREiExQf/aAAgBAQAGPwIStGI8EajT/8QAGxABAAIDAQEAAAAAAAAAAAAAAQARITFBUaH/2gAIAQEAAT8hdFEQPYtoCq7lFl9mtmCsFl5MvU//2gAMAwEAAgADAAAAEF8//8QAFxEBAQEBAAAAAAAAAAAAAAAAAREAIf/aAAgBAwEBPxBnFzTZv//EABgRAAIDAAAAAAAAAAAAAAAAAAABESFh/9oACAECAQE/EKGpFs//xAAaEAEBAQEAAwAAAAAAAAAAAAABEQAhQVGR/9oACAEBAAE/EFouJGKoGp18y8gRecZlNMieHJ4g+oBPWV603//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/1cf0c9321a39b0681be39d8ea400489d/f994e/printaniere.webp 163w", "/static/1cf0c9321a39b0681be39d8ea400489d/bcd70/printaniere.webp 325w", "/static/1cf0c9321a39b0681be39d8ea400489d/5d8d7/printaniere.webp 650w", "/static/1cf0c9321a39b0681be39d8ea400489d/c4443/printaniere.webp 975w", "/static/1cf0c9321a39b0681be39d8ea400489d/5cc32/printaniere.webp 1300w", "/static/1cf0c9321a39b0681be39d8ea400489d/1dd8b/printaniere.webp 2302w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/1cf0c9321a39b0681be39d8ea400489d/367e5/printaniere.jpg 163w", "/static/1cf0c9321a39b0681be39d8ea400489d/ab07c/printaniere.jpg 325w", "/static/1cf0c9321a39b0681be39d8ea400489d/ebabe/printaniere.jpg 650w", "/static/1cf0c9321a39b0681be39d8ea400489d/cdb69/printaniere.jpg 975w", "/static/1cf0c9321a39b0681be39d8ea400489d/2616f/printaniere.jpg 1300w", "/static/1cf0c9321a39b0681be39d8ea400489d/a4af9/printaniere.jpg 2302w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/1cf0c9321a39b0681be39d8ea400489d/ebabe/printaniere.jpg",
              "alt": "bed and breakfast",
              "title": "bed and breakfast",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span><strong parentName="p">{`bed and breakfast`}</strong></p>
    <h3 {...{
      "id": "a-stay-in-a-guest-room-with-the-warm-color-of-the-beautiful-days"
    }}>{`A stay in a `}<strong parentName="h3">{`guest room`}</strong>{` with the warm color of the beautiful days`}</h3>
    <p>{`Relax in this sun room with its decoration that gives it a Tuscany look. You will appreciate its panoramic view of the park of the castle and the valley of `}<strong parentName="p">{`Meurthe et Moselle`}</strong>{`. A large bathroom with a spacious sitting area will allow you to relax during your trip between `}<strong parentName="p">{`Nancy`}</strong>{` and `}<strong parentName="p">{`Metz`}</strong>{`.`}</p>
    <ButtonExternal type="booking" mdxType="ButtonExternal">Book</ButtonExternal>
    <h2 {...{
      "id": "flying-in-the-colvert"
    }}>{`Flying in the Colvert`}</h2>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/6a87ca6c1ae4eb723ab482e7d9b55771/a4af9/colvert.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.87116564417178%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAUDBP/EABYBAQEBAAAAAAAAAAAAAAAAAAIAAf/aAAwDAQACEAMQAAAB79YoOsnrf//EABkQAAMBAQEAAAAAAAAAAAAAAAECEQATEv/aAAgBAQABBQKws4U+rurDdC7wjf/EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABkQAAMBAQEAAAAAAAAAAAAAAAABEQIxIf/aAAgBAQAGPwKEhc8L0VSPNNH/xAAbEAEAAgMBAQAAAAAAAAAAAAABABEhQVExYf/aAAgBAQABPyG2NcGPJgTYPxBjjR3uNVF8nkM4T//aAAwDAQACAAMAAAAQ2M//xAAYEQEBAAMAAAAAAAAAAAAAAAABABEhgf/aAAgBAwEBPxB25S5f/8QAGBEAAgMAAAAAAAAAAAAAAAAAADEBEWH/2gAIAQIBAT8QhMrT/8QAGxABAQADAAMAAAAAAAAAAAAAAREAITFRYZH/2gAIAQEAAT8QdS8Qu9swkjyafcNJ3HjHhuKFWPdyLwoTEPFx0s+Yf//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/6a87ca6c1ae4eb723ab482e7d9b55771/f994e/colvert.webp 163w", "/static/6a87ca6c1ae4eb723ab482e7d9b55771/bcd70/colvert.webp 325w", "/static/6a87ca6c1ae4eb723ab482e7d9b55771/5d8d7/colvert.webp 650w", "/static/6a87ca6c1ae4eb723ab482e7d9b55771/c4443/colvert.webp 975w", "/static/6a87ca6c1ae4eb723ab482e7d9b55771/5cc32/colvert.webp 1300w", "/static/6a87ca6c1ae4eb723ab482e7d9b55771/1dd8b/colvert.webp 2302w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/6a87ca6c1ae4eb723ab482e7d9b55771/367e5/colvert.jpg 163w", "/static/6a87ca6c1ae4eb723ab482e7d9b55771/ab07c/colvert.jpg 325w", "/static/6a87ca6c1ae4eb723ab482e7d9b55771/ebabe/colvert.jpg 650w", "/static/6a87ca6c1ae4eb723ab482e7d9b55771/cdb69/colvert.jpg 975w", "/static/6a87ca6c1ae4eb723ab482e7d9b55771/2616f/colvert.jpg 1300w", "/static/6a87ca6c1ae4eb723ab482e7d9b55771/a4af9/colvert.jpg 2302w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/6a87ca6c1ae4eb723ab482e7d9b55771/ebabe/colvert.jpg",
              "alt": "chambres d’hôtes",
              "title": "chambres d’hôtes",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span><strong parentName="p">{`Chambres d’hôtes`}</strong></p>
    <h3 {...{
      "id": "the-symbol-of-lorraine-will-transport-you-to-an-authentic-moment"
    }}>{`The symbol of `}<strong parentName="h3">{`Lorraine`}</strong>{` will transport you to an authentic moment.`}</h3>
    <p>{`Treat yourself to a night in a 43m2 room with its double `}<strong parentName="p">{`bed`}</strong>{` of 200x200. You will find a bathtub to relax. This `}<strong parentName="p">{`room`}</strong>{` will also allow you to enjoy the view of the valley and the park of the castle. Enjoy the charm of this room which reflects the authenticity of `}<strong parentName="p">{`Lorraine`}</strong>{`.`}</p>
    <ButtonExternal type="booking" mdxType="ButtonExternal">Book</ButtonExternal>
    <h2 {...{
      "id": "lunch-in-complete-tranquility"
    }}>{`Lunch in complete tranquility`}</h2>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/ba3ef274c3ef583c5369d5411d433412/a4af9/pdj.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.87116564417178%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAQDBf/EABYBAQEBAAAAAAAAAAAAAAAAAAECA//aAAwDAQACEAMQAAABtykZ1Q54f//EABoQAQADAAMAAAAAAAAAAAAAAAIAASEDEjL/2gAIAQEAAQUCDNxvL2EdIvV8u//EABURAQEAAAAAAAAAAAAAAAAAAAEQ/9oACAEDAQE/ASf/xAAVEQEBAAAAAAAAAAAAAAAAAAABEP/aAAgBAgEBPwFn/8QAGhAAAgIDAAAAAAAAAAAAAAAAAAEQETJh4f/aAAgBAQAGPwJ6HR2cUf/EABoQAQEBAQEBAQAAAAAAAAAAAAERACExQVH/2gAIAQEAAT8huh/DpLXfNaomFPVVt3E+l+56q7//2gAMAwEAAgADAAAAENTv/8QAFhEBAQEAAAAAAAAAAAAAAAAAAQAR/9oACAEDAQE/EAWt/8QAFxEBAQEBAAAAAAAAAAAAAAAAAQARQf/aAAgBAgEBPxBPLC//xAAeEAEBAAEDBQAAAAAAAAAAAAABEQAhQWExUXGhwf/aAAgBAQABPxA8oppxkEhEYa03n3EGGhsMWi+mpre2RcDMTXU399MNaHyTjP/Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/ba3ef274c3ef583c5369d5411d433412/f994e/pdj.webp 163w", "/static/ba3ef274c3ef583c5369d5411d433412/bcd70/pdj.webp 325w", "/static/ba3ef274c3ef583c5369d5411d433412/5d8d7/pdj.webp 650w", "/static/ba3ef274c3ef583c5369d5411d433412/c4443/pdj.webp 975w", "/static/ba3ef274c3ef583c5369d5411d433412/5cc32/pdj.webp 1300w", "/static/ba3ef274c3ef583c5369d5411d433412/1dd8b/pdj.webp 2302w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/ba3ef274c3ef583c5369d5411d433412/367e5/pdj.jpg 163w", "/static/ba3ef274c3ef583c5369d5411d433412/ab07c/pdj.jpg 325w", "/static/ba3ef274c3ef583c5369d5411d433412/ebabe/pdj.jpg 650w", "/static/ba3ef274c3ef583c5369d5411d433412/cdb69/pdj.jpg 975w", "/static/ba3ef274c3ef583c5369d5411d433412/2616f/pdj.jpg 1300w", "/static/ba3ef274c3ef583c5369d5411d433412/a4af9/pdj.jpg 2302w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/ba3ef274c3ef583c5369d5411d433412/ebabe/pdj.jpg",
              "alt": "breakfast",
              "title": "breakfast",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span><strong parentName="p">{`breakfast and relax`}</strong></p>
    <h3 {...{
      "id": "a-moment-of-intimacy-in-the-early-morning-in-lorraine-is-guaranteed"
    }}>{`A moment of intimacy in the early morning in `}<strong parentName="h3">{`Lorraine`}</strong>{` is guaranteed.`}</h3>
    <p>{`Here is the breakfast formula:`}</p>
    <ul>
      <li parentName="ul">{`Hot drinks ☕`}</li>
      <li parentName="ul">{`Viennese pastries 🍞`}</li>
      <li parentName="ul">{`Organic orange juice 🍊`}</li>
      <li parentName="ul">{`Yogurt and fruit 🍶🍐🍏`}</li>
      <li parentName="ul">{`You would appreciate this complimentary breakfast which is composed of local and Lorraine products.`}</li>
    </ul>
    <h2 {...{
      "id": "small-details-are-often-appreciated"
    }}>{`Small details are often appreciated`}</h2>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "640px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/2b4e4e518b5a879bf4410b0cf46bc522/d6032/chateau_de_morey_mariage.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.25766871165644%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAMBAgT/xAAVAQEBAAAAAAAAAAAAAAAAAAAAAf/aAAwDAQACEAMQAAAB2UIGCCT/xAAZEAADAAMAAAAAAAAAAAAAAAAAAREQITH/2gAIAQEAAQUCWhM7iEP/xAAVEQEBAAAAAAAAAAAAAAAAAAAAIf/aAAgBAwEBPwFX/8QAFREBAQAAAAAAAAAAAAAAAAAAACH/2gAIAQIBAT8BV//EABgQAAIDAAAAAAAAAAAAAAAAAAABESBR/9oACAEBAAY/AnI9r//EABsQAAMBAAMBAAAAAAAAAAAAAAABESExQXGR/9oACAEBAAE/IXdWq6GxLp5TYIex65f0/9oADAMBAAIAAwAAABCEL//EABcRAQADAAAAAAAAAAAAAAAAAAABQWH/2gAIAQMBAT8QpOH/xAAYEQACAwAAAAAAAAAAAAAAAAAAEQEhQf/aAAgBAgEBPxBWPEH/xAAcEAEAAwADAQEAAAAAAAAAAAABABEhMUGB4fD/2gAIAQEAAT8Qojs8EMc2HqDPswXLVKbsS1rnHcfwQ3XjSf/Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/2b4e4e518b5a879bf4410b0cf46bc522/f994e/chateau_de_morey_mariage.webp 163w", "/static/2b4e4e518b5a879bf4410b0cf46bc522/bcd70/chateau_de_morey_mariage.webp 325w", "/static/2b4e4e518b5a879bf4410b0cf46bc522/797b9/chateau_de_morey_mariage.webp 640w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/2b4e4e518b5a879bf4410b0cf46bc522/367e5/chateau_de_morey_mariage.jpg 163w", "/static/2b4e4e518b5a879bf4410b0cf46bc522/ab07c/chateau_de_morey_mariage.jpg 325w", "/static/2b4e4e518b5a879bf4410b0cf46bc522/d6032/chateau_de_morey_mariage.jpg 640w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/2b4e4e518b5a879bf4410b0cf46bc522/d6032/chateau_de_morey_mariage.jpg",
              "alt": "bed and breakfast between nancy and metz",
              "title": "bed and breakfast between nancy and metz",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span><strong parentName="p">{`bed and breakfast between nancy and metz`}</strong></p>
    <p>{`You will not lack anything during your stay between Nancy and Metz.
We know that it matters, the equipment of our rooms are there to perfect your trip in Meurthe et Moselle, Lorraine or Nancy. Find in all our rooms :`}</p>
    <ul>
      <li parentName="ul">{`Wifi access 🌐`}</li>
      <li parentName="ul">{`Hair dryer 💇♀`}</li>
      <li parentName="ul">{`Shower gel & shampoo 🚿`}</li>
      <li parentName="ul">{`Towels & Bathrobes 💆♀`}</li>
      <li parentName="ul">{`Documentation on Lorraine 📜`}</li>
      <li parentName="ul">{`Lounge area 🛋`}</li>
      <li parentName="ul">{`Marvel your moment near Nancy and Metz with multiple activities in our establishment, such as SPA, massage, swimming pool, horseback riding…`}</li>
    </ul>
    <ButtonInternal to="/en/bed-and-breakfast/airbnb-nancy-metz" mdxType="ButtonInternal">See more</ButtonInternal>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      